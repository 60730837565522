<template>
  <div :class="wrapperClassBind">
    <base-header/>
    <div v-loading="isLoading" class="l-container">
      <nuxt />
    </div>
    <base-footer/>
    <auto-logout-modal />
    <duplicate-login-modal />
  </div>
</template>

<script>
import { mapState , mapMutations } from 'vuex'
import { v4 as uuidv4 } from 'uuid'

const BaseHeader = () => import('@/components/layouts/BaseHeader') // 헤더
const BaseFooter = () => import('@/components/layouts/BaseFooter') // 풋터
const AutoLogoutModal = () => import('@/components/common/AutoLogoutModal') // 자동 로그아웃
const DuplicateLoginModal = () => import('@/components/common/DuplicateLoginModal') // 중복 로그인

export default {
  name: 'DefaultLayouts',

  components: {
    BaseHeader,
    BaseFooter,
    AutoLogoutModal,
    DuplicateLoginModal
  },

  beforeRouterLeave(to, from, next) {
    console.log(to, from)
  },

  data() {
    return {
      // isLogin: false,
      isLoading: false
    }
  },

  computed: {

    wrapperClassBind() {
      const className = ['l-wrap']
      if (this.isLogin) {
        className.push('l-wrap__active')
      }
      return className.join(' ')
    },

    ...mapState({
      isLogin: state => state.commonStore.isLogin
    })

  },


  beforeMount() {

    /* 로그인 여부 기준 X-Lina-Client-Id 생성 */
    if(!this.isLogin) {

      this.setLinaClientId()
    }
  },


  created() {

    this.$nuxt.$on('onLoading', this.onLoading)

    /* 로그아웃 상태 접근 방지 (로그인 페이지 이동) */
    if (this.isLogin === false) {
      this.$router.push("/")
    }
  },

  methods: {

    ...mapMutations('commonStore', ['setUuid']),
    onLoading(loading = false) {
      this.isLoading = loading

    },

    /* X-Lina-Client-Id 생성 */
    async setLinaClientId() {
      if (!sessionStorage.getItem('partner-puuidv4')) {
        this.uuid = await uuidv4().replaceAll('-', '')
        await sessionStorage.setItem('partner-uuidv4', this.uuid)
        await this.setUuid(this.uuid)
      } else {
        await this.setUuid(this.uuid)
      }
    },
  }
}

</script>


<style lang="scss">
.l {
  &-container {
    padding: 5.3rem 0 13rem;
    width: 100%;
    max-width: 1920px;
    min-width: 1300px;
    min-height: calc(100vh - 12rem);
  }
  &-wrap__active {
    .l-container {
      max-width: 1920px;
      min-width: 1300px;

      min-height: calc(100vh - 16.2rem - 12rem);
    }
  }
}
</style>
