import commonData from '@/util/dummy/common'

const commonStore = {
  namespaced: true,
  state: {
    tokenInfo: null,
    userInfo: null,
    isLogin: false,
    uuid: null,
    menus: commonData.menusData,
    commonMenu: commonData.commonMenu,
    timeoutState: false
  },
  getters: {},
  mutations: {
    setUuid(state, payload) {
      state.uuid = payload
    },
    setTokenInfo(state, payload) {
      state.isLogin = true
      state.tokenInfo = payload
    },
    setUserInfo(state, payload) {
      state.isLogin = true
      state.userInfo = payload
    },
    logout(state) {
      
      state.isLogin = false
      state.userInfo = null
      state.tokenInfo = null

      // 로그아웃 세션 및 스토리지 값 초기화
      // console.log('동작확인 여부 로그아웃')
      sessionStorage.removeItem('token')
      localStorage.removeItem('token')
      localStorage.clear()
      sessionStorage.clear()
    },
    setTimeoutState(state, payload) {
      state.timeoutState = payload
    }
  },
  actions: {}
}

export default commonStore
