import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _253c8c7d = () => interopDefault(import('../pages/calculate-meg/index.vue' /* webpackChunkName: "pages/calculate-meg/index" */))
const _77d4edd5 = () => interopDefault(import('../pages/confirm/index.vue' /* webpackChunkName: "pages/confirm/index" */))
const _7e67e9c4 = () => interopDefault(import('../pages/mobile-access/index.vue' /* webpackChunkName: "pages/mobile-access/index" */))
const _7d1858b9 = () => interopDefault(import('../pages/notice/index.vue' /* webpackChunkName: "pages/notice/index" */))
const _33b44760 = () => interopDefault(import('../pages/performance-status/index.vue' /* webpackChunkName: "pages/performance-status/index" */))
const _f8e15db4 = () => interopDefault(import('../pages/page-status/500-error/index.vue' /* webpackChunkName: "pages/page-status/500-error/index" */))
const _574c518f = () => interopDefault(import('../pages/page-status/system/index.vue' /* webpackChunkName: "pages/page-status/system/index" */))
const _eb9721b8 = () => interopDefault(import('../pages/notice/_id/index.vue' /* webpackChunkName: "pages/notice/_id/index" */))
const _4dee4c64 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/calculate-meg",
    component: _253c8c7d,
    name: "calculate-meg"
  }, {
    path: "/confirm",
    component: _77d4edd5,
    name: "confirm"
  }, {
    path: "/mobile-access",
    component: _7e67e9c4,
    name: "mobile-access"
  }, {
    path: "/notice",
    component: _7d1858b9,
    name: "notice"
  }, {
    path: "/performance-status",
    component: _33b44760,
    name: "performance-status"
  }, {
    path: "/page-status/500-error",
    component: _f8e15db4,
    name: "page-status-500-error"
  }, {
    path: "/page-status/system",
    component: _574c518f,
    name: "page-status-system"
  }, {
    path: "/notice/:id",
    component: _eb9721b8,
    name: "notice-id"
  }, {
    path: "/",
    component: _4dee4c64,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
