/**
 * 휘발성 Store
 * 새로고침시 초기화
 */
const refreshStore = {
  namespaced: true,
  state: {
    loadingState: { // 공통 로딩 처리
      visible: false,
      message: '처리중입니다.',
      description: '잠시만 기다려주십시오.'
    },
    messageState: {
      visible: false,
      messageInfo: {
        title: '',
        message: '',
        errorData: {
          txId: '',
          stackTrace: ''
        },
        positive: {
          value: '',
          callback: null
        },
        negative: {
          value: '',
          callback: null
        },
        prevFocusElementId: ''
      }
    },
    blockToLeave: {
      isBlock: false,
      callback: null
    }
  },
  mutations: {
    showMessageState: (state, payload) => {
      state.messageState = {
        visible: true,
        ...payload
      }
    },
    hideMessageState: (state) => {
      state.messageState.visible = false
    },
    toggleLoadingState: (state, payload) => {
      state.loadingState.visible = payload.visible
      if (payload.visible) {
        state.loadingState.message = payload.message || '처리중입니다.'
        state.loadingState.description = payload.description || ''
      } else {
        state.loadingState.message = '처리중입니다.'
        state.loadingState.description = '잠시만 기다려주십시오.'
      }
    },
    setBlockToLeave: (state, payload) => {
      state.blockToLeave.isBlock = true
      state.blockToLeave.callback = payload
    },
    resetBlockToLeave: (state) => {
      state.blockToLeave.isBlock = false
      state.blockToLeave.callback = null
    }
  },
  actions: {}
}

export default refreshStore
