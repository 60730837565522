import { $api } from '@/api'

export default (ctx, inject) => {
  
  /* API 요청 헤더 처리 과정 */
  $api.interceptors.request.use(
    function(config) {
      config.baseURL = ctx.$config.VUE_APP_API_DOMAIN
      const store = ctx.store
      const result = /[\\/]api[\\/]/.test(config.url)

      if (result) {
        const token = JSON.parse(sessionStorage.getItem('token'))
        if (token !== '' && token !== null) {
          config.headers.Authorization = token.token.idToken // idToekn 값 필수
        }
      }
      // 필수 포함항목
      // X-LINA-CLIENT-ID: clinet 생성 UUID
      config.headers['X-LINA-CLIENT-ID'] =
        sessionStorage.getItem('partner-uuidv4')
      config.headers['X-APP-CLIENT-ID'] = process.env.VUE_APP_CLIENT_ID_WEB // X-APP-CLIENT-ID: Cognito App Client ID
      config.headers.InflowPath = 'HOM' // InflowPath: PC(HOM) || MobileWeb(MBH) || MobileApp(APH)


      /* 로그인 이후 세션타입아웃 관리 */
      if (store.state.commonStore.isLogin) {
        store.commit('commonStore/setTimeoutState', true)
      }

      return config
    },

    function(error) {
      console.log(error)
      // 오류 요청을 보내기전 수행할 일
      // ...
      return Promise.reject(error)
    }
  )
  
  /* API 응답 처리 */
  $api.interceptors.response.use(
    (response) => {
      return response
    },
    (responseError) => {
      console.log('api Error::', responseError, responseError.response)
      const errorData = responseError.response.data
      const status = responseError.response.status

      /* 중복 로그인 확인 */
      const throwErrorDuplicateLoginStatus = [403]

      if (throwErrorDuplicateLoginStatus.includes(status)) {
        
        if (responseError.response.data.errorCode === 'COMM1008') { // ERROR EXPIRED_SESSION
     
          ctx.store.commit('refreshStore/showMessageState', {
            messageInfo: {
              message: '새로운 기기에서 로그인 되었습니다.',
              positive: {
                callback: () => {
                  ctx.redirect('/')
                }
              }
            }
          })
 
          this.$router.push('/')
        }
      }

      /* API 응답 기준 에러 페이지 이동 */
      const throwErrorPageStatus = [404]
      if (throwErrorPageStatus.includes(status)) {
        /**
         * 에러페이지 이동
         */
        return ctx.error({ statusCode: status, message: errorData.message })
      } else {
        /**
         * 페이지에서 error처리 O
         * try catch
         */
        return Promise.reject(responseError.response)
      }

      
    },

    
  )

  ctx.$api = $api
  inject('api', ctx.$api)
} 
