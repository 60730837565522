export default function({ store, route, redirect }) {
  const userAgent = navigator.userAgent.toLowerCase()
  const isAndroid = userAgent.match(/android/i)
  const isIos = userAgent.match(/iphone|ipad/i)
  if (isAndroid || isIos) {
    if (route.path !== '/mobile-access') {
      redirect('/mobile-access')
    }
  }

  if (process.client) {
    /**
     * 로그인 이후 세션타입아웃 관리
     */
    if (store.state.commonStore.isLogin) {
      store.commit('commonStore/setTimeoutState', true)
    }
  }
}