<template>
  <nuxt />
</template>

<script>
export default {
  name: 'EmptyLayout'
}
</script>

<style></style>
