import sha256 from 'crypto-js/sha256'
import Base64url from 'crypto-js/enc-base64url'
import _ from 'lodash'

export class Utils {
  /**
   * 설명 : json data를 query string의 key, value 형태로 으로 변환해줍니다.
   * @namespace generateQuerystring
   * @param {object} obj - json 객체
   * @returns {string} - query string으로 변환된 문자열
   */
  generateQuerystring(obj) {
    return Object.entries(obj)
      .map(e => e.join('='))
      .join('&')
  }

  getCustomGridCell(type, data) {
    let cellElm = ''
    switch (type) {
      case 'plain-button':
        cellElm = `
        <div class="cell cell__plain">
          <button id="${data.buttonId}" class="cell-button__plain" onclick="${data.func}">${data.value}</button>
        </div>
        `
        break
      case 'channel-tag':
        cellElm = `
        <div class="cell">
          <span class="tag" style="background-color: ${data.color}">${data.value}</span>
        </div>
        `
        break
    }

    return cellElm
  }

  maskingAccountNo(value) {
    if (typeof value !== 'string') {
      return value
    }

    if (value.length > 10) {
      const PATTERN_REGEX = /([0-9]{4})[0-9]{6}([0-9]*)/
      const MASKING_PATTERN = '$1******$2'

      return value.replace(PATTERN_REGEX, MASKING_PATTERN)
    } else {
      return value
    }
  }

  maskingCardNo(value) {
    if (typeof value !== 'string') {
      return value
    }

    const PATTERN_REGEX = /([0-9]{6})[0-9]{0,6}([0-9]*)/
    const MASKING_PATTERN = '$1******$2'

    const cardNoLength = value.length
    const cardNoSplitLength = value.split('-').length
    const replaceValue = value
      .replaceAll('-', '')
      .replace(PATTERN_REGEX, MASKING_PATTERN)

    if (cardNoLength === 16) {
      return replaceValue
    } else if (cardNoLength === 15) {
      return replaceValue
    } else if (cardNoLength === 19 && cardNoSplitLength === 4) {
      return (
        replaceValue.substring(0, 4) +
        '-' +
        replaceValue.substring(4, 8) +
        '-' +
        replaceValue.substring(8, 12) +
        '-' +
        replaceValue.substring(12, 16)
      )
    } else if (cardNoLength === 18 && cardNoSplitLength === 4) {
      return (
        replaceValue.substring(0, 4) +
        '-' +
        replaceValue.substring(4, 8) +
        '-' +
        replaceValue.substring(8, 12) +
        '-' +
        replaceValue.substring(12, 15)
      )
    } else {
      return value
    }
  }

  maskingEmail(value) {
    if (typeof value !== 'string') {
      return value
    }

    const PATTERN_REGEX = /\b(\S+)+@(\S+.\S+)/
    const MASKING_PATTERN = '@$2'

    const resultArr = value.match(PATTERN_REGEX)

    if (resultArr && resultArr.length >= 3) {
      let replaceStr = resultArr[1].substring(0, 2)

      for (let i = 0; i < resultArr[1].length - 2; i++) {
        replaceStr += '*'
      }

      return replaceStr + value.replace(PATTERN_REGEX, MASKING_PATTERN)
    } else {
      return value
    }
  }

  maskingRrno(value) {
    if (typeof value !== 'string') {
      return value
    }

    const PATTERN_REGEX = /(\d{6})(-|\s| |\t|\n|\f|\r)*(\d)\d{6}/
    const MASKING_PATTERN = '$1$2$3******'

    return value.replace(PATTERN_REGEX, MASKING_PATTERN)
  }

  maskingName(value) {
    if (typeof value !== 'string') {
      return value
    }

    const PATTERN_REGEX = /(\w|[ㄱ-힇]{1})(.*)(\w|[ㄱ-힇]{1})/

    const resultArr = value.match(PATTERN_REGEX)

    if (resultArr && resultArr.length >= 3) {
      let maskingPattern = '$1'

      for (let i = 0; i < resultArr[2].length; i++) {
        maskingPattern += '*'
      }

      maskingPattern += '$3'

      return value.replace(PATTERN_REGEX, maskingPattern)
    } else {
      return value
    }
  }

  maskingTelNo(value) {
    if (typeof value !== 'string') {
      return value
    }

    const PATTERN_REGEX =
      /(01[0|1|7|8|9]|0(?:2|3[1-3]|4[1-4]|5[0-5]|6[0-4]|70|80))([-| |\t|\n|\f|\r]*)(\d{3,4})([-| |\t|\n|\f|\r]*)(\d{4})/

    const resultArr = value.match(PATTERN_REGEX)

    if (resultArr && resultArr.length >= 6) {
      let maskingPattern = '$1$2'

      for (let i = 0; i < resultArr[3].length; i++) {
        maskingPattern += '*'
      }

      maskingPattern += '$4$5'

      return value.replace(PATTERN_REGEX, maskingPattern)
    } else {
      return value
    }
  }

  maskingContNo(value) {
    if (typeof value !== 'string') {
      return value
    }

    const PATTERN_REGEX = /(.{9})(.*)/
    const MASKING_PATTERN = '*********$2'

    return value.replace(PATTERN_REGEX, MASKING_PATTERN)
  }

  maskingFullAddress(value) {
    if (typeof value !== 'string') {
      return value
    }

    const PATTERN_REGEX =
      /(?:(?:[가-힣A-Za-z·\d~\-\\.]{2,}(?:로|길).[\d]+)|(?:[가-힣A-Za-z·\d~\-\\.]+(?:읍|동|리)\s)[\d]+)(?<detailAddr>.*)/
    const resultArr = value.match(PATTERN_REGEX)

    if (resultArr?.groups?.detailAddr) {
      const matchedStr = resultArr.groups.detailAddr

      if (matchedStr.replace(' ', '').length < 10) {
        return value.replace(matchedStr, ' **********')
      }

      const REPLACE_REGEX = /[^\s]/g
      const replaceStr = matchedStr.replace(REPLACE_REGEX, '*')

      return value.replace(matchedStr, replaceStr)
    } else {
      return value
    }
  }

  maskingDetailAddress(value) {
    if (typeof value !== 'string') {
      return value
    }

    if (value.replace(' ', '').length < 10) {
      return ' **********'
    }

    const PATTERN_REGEX = /[^\s]/g

    return value.replace(PATTERN_REGEX, '*')
  }

  maxlength(value, maxlength) {
    if (typeof value === 'undefined' || isNaN(parseInt(maxlength))) {
      return value
    }

    if (value.length <= maxlength) {
      return value
    }

    return value.slice(0, maxlength)
  }

  maxNumber(value, max) {
    if (typeof value === 'undefined') {
      return ''
    }

    const rtnValue = value.replace(/\D/, '')

    if (isNaN(parseInt(rtnValue)) || isNaN(parseInt(max))) {
      return ''
    }

    if (rtnValue <= max) {
      return rtnValue
    }

    return max
  }

  isBlank(str) {
    if (str) {
      return str.trim() === ''
    } else {
      return true
    }
  }

  formatStringDate(dateStr, division = '-') {
    if (typeof dateStr !== 'string' || dateStr.length !== 8) {
      return dateStr
    }

    return dateStr.replace(
      /(\d{4})(\d{2})(\d{2})/,
      `$1${division}$2${division}$3`
    )
  }

  formatDateTime(date, format = 'yyyy-MM-dd hh:mm:ss') {
    if (typeof date !== 'object' || !(date instanceof Date)) {
      return date
    }

    const weekName = [
      '일요일',
      '월요일',
      '화요일',
      '수요일',
      '목요일',
      '금요일',
      '토요일'
    ]

    return format.replace(
      /(yyyy|yy|MM|M|dd|d|E|hh|h|mm|m|SSS|SS|S|ss|s|a)/gi,
      el => {
        switch (el) {
          case 'yyyy':
            return String(date.getFullYear())
          case 'yy':
            return String(date.getFullYear() % 1000).padStart(2, '0')
          case 'MM':
            return String(date.getMonth() + 1).padStart(2, '0')
          case 'M':
            return String(date.getMonth() + 1)
          case 'dd':
            return String(date.getDate()).padStart(2, '0')
          case 'd':
            return String(date.getDate())
          case 'E':
            return weekName[date.getDay()]
          case 'HH':
            return String(date.getHours()).padStart(2, '0')
          case 'H':
            return String(date.getHours())
          case 'hh':
            return String(
              date.getHours() % 12 ? date.getHours() % 12 : 12
            ).padStart(2, '0')
          case 'h':
            return String(date.getHours() % 12 ? date.getHours() % 12 : 12)
          case 'mm':
            return String(date.getMinutes()).padStart(2, '0')
          case 'm':
            return String(date.getMinutes())
          case 'SSS':
            return String(date.getMilliseconds()).padStart(3, '0')
          case 'SS':
            return String(date.getMilliseconds()).padStart(2, '0')
          case 'S':
            return String(date.getMilliseconds())
          case 'ss':
            return String(date.getSeconds()).padStart(2, '0')
          case 's':
            return String(date.getSeconds())
          case 'a':
            return date.getHours() < 12 ? '오전' : '오후'
          default:
            return el
        }
      }
    )
  }

  formatStringTime(timeStr, division = ':') {
    if (
      typeof timeStr !== 'string' ||
      !(timeStr.length === 4 || timeStr.length === 6)
    ) {
      return timeStr
    }

    let formatStr = ''

    const hourStr = timeStr.substring(0, 2)
    const hour = parseInt(hourStr)

    if (hour < 12) {
      formatStr = '오전 '
    } else {
      formatStr = '오후 '
    }

    formatStr += String(hour % 12 ? hour % 12 : 12).padStart(2, '0')

    formatStr += timeStr.substring(2)

    if (timeStr.length === 4) {
      return formatStr.replace(
        /(오전|오후)\s(\d{2})(\d{2})/,
        `$1 $2${division}$3`
      )
    } else {
      return formatStr.replace(
        /(오전|오후)\s(\d{2})(\d{2})(\d{2})/,
        `$1 $2${division}$3${division}$4`
      )
    }
  }

  formatTelno(telNo) {
    if (typeof telNo !== 'string') {
      return telNo
    }

    if (telNo.replace(/\W/, '').length === 11) {
      return telNo
        .replace(/\W/, '')
        .replace(
          /(01[0|1|7|8|9]|0(?:2|3[1-3]|4[1-4]|5[0-5]|6[0-4]|70|80))(\d{4})(\d{4})/,
          '$1-$2-$3'
        )
    } else if (
      telNo.replace(/\W/, '').length === 10 ||
      telNo.replace(/\W/, '').length === 9
    ) {
      return telNo
        .replace(/\W/, '')
        .replace(
          /(01[0|1|7|8|9]|0(?:2|3[1-3]|4[1-4]|5[0-5]|6[0-4]|70|80))(\d{3})(\d{4})/,
          '$1-$2-$3'
        )
    }

    return telNo
      .replace(/\W/, '')
      .replace(
        /(01[0|1|7|8|9]|0(?:2|3[1-3]|4[1-4]|5[0-5]|6[0-4]|70|80))(\d{0,4})(\d{0,4})/,
        '$1-$2-$3'
      )
      .replace(/-{2,}/, '')
  }

  formatSuffix(value, suffix) {
    return value + '' + suffix
  }

  formatRate(rate, fixed = 1) {
    if (isNaN(rate)) {
      return rate
    }

    if (isNaN(rate) || !rate.toFixed) {
      const rateParse = parseFloat(rate)

      return this.formatSuffix(rateParse.toFixed(fixed), '%')
    } else {
      return this.formatSuffix(rate.toFixed(fixed), '%')
    }
  }

  formatComma(value) {
    return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  formatCurrency(value, suffix = '원') {
    return this.formatSuffix(this.formatComma(value), suffix)
  }

  formatCompanyNumber(compNo) {
    if (typeof compNo !== 'string') {
      return compNo
    }

    if (compNo.replace(/\W/, '').length === 12) {
      return compNo
        .replace(/\W/, '')
        .replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3')
    }

    return compNo
      .replace(/\W/, '')
      .replace(/(\d{3})(\d{0,2})(\d{0,5})/, '$1-$2-$3')
      .replace(/-{2,}/, '')
  }

  /**
   * 설명 : SHA256 Hash
   * @namespace hashSha256
   * @param {string} value - (필수)SHA256 hash할 문자열
   * @param {string} nonce - (optional) 임시값
   * @return {string} sh256 hash 문자열
   */
  hashSha256(value, nonce) {
    if (nonce === null || nonce === '' || nonce === undefined) {
      nonce = 'partner'
    }

    value = nonce + value

    if (process.env.SERVER_TYPE === 'prd') {
      value = value + process.env.SERVER_TYPE
    }

    return Base64url.stringify(sha256(value))
  }

  /**
   * 설명 : unescape(html문자 unescape처리)
   * @namespace getUnescape
   * @param {string} val - escape된 string
   * @return {string} unescape된 string
   */
  getUnescape(val) {
    return _.unescape(val)  // lodash lib사용
  }
}

export const utils = new Utils()
