import axios from 'axios'
import qs from 'qs'

const $api = axios.create({
  'Content-Type': 'application/json',
  paramsSerializer: (params) => {
    return qs.stringify(params, { allowDots: true })
  }
})

export { $api }
