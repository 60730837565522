import Vue from 'vue'

const BaseTitles = () => import('@/components/common/BaseTitles');
const BaseTag = () => import('@/components/common/BaseTag');
const BaseList = () => import('@/components/common/BaseList');
const BaseButtons = () => import('@/components/common/BaseButtons');
const BaseRadio = () => import('@/components/common/BaseRadio');
const BaseRadioButtons = () => import('@/components/common/BaseRadioButtons');
const BaseCardList = () => import('@/components/common/BaseCardList');
const BaseTable = () => import('@/components/common/BaseTable');
const BaseTableCalculMeg = () => import('@/components/common/BaseTableCalculMeg');
const BaseDescriptions = () => import('@/components/common/BaseDescriptions');
const BaseListTable = () => import('@/components/common/BaseListTable');
const BaseTab = () => import('@/components/common/BaseTab');
const BaseAccordion = () => import('@/components/common/BaseAccordion');
const BasePagination = () => import('@/components/common/BasePagination');
const BaseInput = () => import('@/components/common/BaseInput');
const BaseSwitch = () => import('@/components/common/BaseSwitch');
const BaseCheckbox = () => import('@/components/common/BaseCheckbox');
const BaseLinkButtons = () => import('@/components/common/BaseLinkButtons');
const BaseModal = () => import('@/components/common/BaseModal');
const BaseSelect = () => import('@/components/common/BaseSelect');
const BaseSlider = () => import('@/components/common/BaseSlider');
const BaseTooltip = () => import('@/components/common/BaseTooltip');
const BaseStep = () => import('@/components/common/BaseStep');
const BaseStepProgress = () => import('@/components/common/BaseStepProgress');
const BaseDatePicker = () => import('@/components/common/BaseDatePicker');
const BaseMessagebox = () => import('@/components/common/BaseMessagebox');
const BaseDrawer = () => import('@/components/common/BaseDrawer');
const BaseImage = () => import('@/components/common/BaseImage');

Vue.component('BaseTitles', BaseTitles)
Vue.component('BaseTag', BaseTag)
Vue.component('BaseList', BaseList)
Vue.component('BaseButtons', BaseButtons)
Vue.component('BaseRadio', BaseRadio)
Vue.component('BaseRadioButtons', BaseRadioButtons)
Vue.component('BaseCardList', BaseCardList)
Vue.component('BaseTable', BaseTable)
Vue.component('BaseTableCalculMeg', BaseTableCalculMeg)
Vue.component('BaseDescriptions', BaseDescriptions)
Vue.component('BaseListTable', BaseListTable)
Vue.component('BaseTab', BaseTab)
Vue.component('BaseAccordion', BaseAccordion)
Vue.component('BasePagination', BasePagination)
Vue.component('BaseInput', BaseInput)
Vue.component('BaseSwitch', BaseSwitch)
Vue.component('BaseCheckbox', BaseCheckbox)
Vue.component('BaseLinkButtons', BaseLinkButtons)
Vue.component('BaseModal', BaseModal)
Vue.component('BaseSelect', BaseSelect)
Vue.component('BaseSlider', BaseSlider)
Vue.component('BaseTooltip', BaseTooltip)
Vue.component('BaseStep', BaseStep)
Vue.component('BaseStepProgress', BaseStepProgress)
Vue.component('BaseDatePicker', BaseDatePicker)
Vue.component('BaseDatePicker', BaseDatePicker)
Vue.component('BaseDatePicker', BaseDatePicker)
Vue.component('BaseMessagebox', BaseMessagebox)
Vue.component('BaseMessagebox', BaseMessagebox)
Vue.component('BaseDrawer', BaseDrawer)
Vue.component('BaseImage', BaseImage)
